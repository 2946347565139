/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersImportCollumns } from './usersImportCollumns';
import { SupervisorType } from './supervisorType';


/**
 * 
 */
export interface PickUsersImportReferenceTypeColumnsMappingTrainingIdsOrganisationIdLanguageCountryCodeRolesLicenseAccountIdLicenseFromLicenseToCoordinatingSupervisorId { 
    /**
     * 
     */
    reference?: string;
    /**
     * 
     */
    type?: PickUsersImportReferenceTypeColumnsMappingTrainingIdsOrganisationIdLanguageCountryCodeRolesLicenseAccountIdLicenseFromLicenseToCoordinatingSupervisorId.TypeEnum;
    columnsMapping: UsersImportCollumns;
    /**
     * 
     */
    trainingIds?: Array<number>;
    /**
     * 
     */
    organisationId?: number;
    /**
     * 
     */
    language?: string;
    /**
     * 
     */
    countryCode?: string;
    /**
     * 
     */
    roles?: Array<SupervisorType>;
    /**
     * 
     */
    licenseAccountId?: string;
    /**
     * 
     */
    licenseFrom?: string;
    /**
     * 
     */
    licenseTo?: string;
    /**
     * 
     */
    coordinatingSupervisorId?: number;
}
export namespace PickUsersImportReferenceTypeColumnsMappingTrainingIdsOrganisationIdLanguageCountryCodeRolesLicenseAccountIdLicenseFromLicenseToCoordinatingSupervisorId {
    export type TypeEnum = 'assistant' | 'supervisor';
    export const TypeEnum = {
        Assistant: 'assistant' as TypeEnum,
        Supervisor: 'supervisor' as TypeEnum
    };
}


